/**
 */
Pmp.modules = Pmp.modules || {}
Pmp.modules.ClientTable = {
  renderClients: function (
    manager_base_url,
    email,
    media_url,
    search_query,
    filter_offset,
    filter_birthday,
    filter_last_visit,
    filter_last_order,
    filter_visits,
    filter_orders,
    filter_rating,
    filter_gender,
    filter_status,
    filter_contact_info,
    filter_member_group,
    filter_bb_name,
    filter_marketing_opt_in,
    filter_marketing_opt_in_text,
    is_sms_marketing_enabled,
    filter_tags,
    exclude_tags,
    client_tags_url,
    member_groups_url,
    url_key,
    bb_users,
    venue_list_dict,
    elementToMount,
    any_client_tags,
    show_order_stats,
    can_view_client_spend_data,
    can_view_clients_across_venue_group,
    error_message,
    venue_name
  ) {
    let attributes_map = {
      name_first_first_display: {
        canonical: 'name_lower_first_last',
        column: 'client',
        display: 'Name',
        sorter: 'alphabetic',
      },
      phone_number_formatted: {
        canonical: 'phone_number',
        display: 'Phone',
      },
      email: {
        canonical: 'email_address',
        display: 'Email',
      },
      company: { canonical: 'company', display: 'Company', sorter: '' },
      title: { canonical: 'title', display: 'Job Title', sorter: '' },
      total_visits: {
        canonical: 'total_visits',
        display: 'Visits',
        column: 'visits',
        sorter: can_view_clients_across_venue_group ? 'numeric' : '',
      },
    }
    if (show_order_stats) {
      attributes_map['total_order_count'] = {
        canonical: 'total_order_count',
        display: 'Orders',
        column: 'orders',
        sorter: can_view_clients_across_venue_group ? 'numeric' : '',
      }
    }
    if (can_view_client_spend_data) {
      attributes_map['total_spend_formatted_no_decimals'] = {
        canonical: 'total_spend',
        display: 'Spend',
        sorter: can_view_clients_across_venue_group ? 'numeric' : '',
      }
      attributes_map['total_spend_per_visit_formatted'] = {
        canonical: 'total_spend_per_visit',
        column: 'spendcover',
        display: 'Spend/Visit',
        sorter: can_view_clients_across_venue_group ? 'numeric' : '',
      }
      attributes_map['total_spend_per_cover_formatted'] = {
        canonical: 'total_spend_per_cover',
        column: 'spendcover',
        display: 'Spend/Cover',
        sorter: can_view_clients_across_venue_group ? 'numeric' : '',
      }
    }
    attributes_map['notes'] = { canonical: 'notes', display: 'Profile Notes', sorter: '' }

    const response_attributes = _.keys(attributes_map),
      visits_lookup = {
        Any: '',
        'One visit': '1-1',
        '1-10 visits': '1-10',
        '10+ visits': '10-',
      },
      orders_lookup = {
        Any: '',
        'One order': '1-1',
        '1-10 orders': '1-10',
        '10+ orders': '10-',
      },
      status_lookup = { Any: '', VIP: 'vip' },
      last_visit_lookup = {
        Any: '',
        'Last Month': '1',
        'Last 3 Months': '3',
        'Last 6 Months': '6',
      },
      last_order_lookup = {
        Any: '',
        'Last Month': '1',
        'Last 3 Months': '3',
        'Last 6 Months': '6',
      },
      birthday_lookup = {
        Any: '',
        January: '1',
        February: '2',
        March: '3',
        April: '4',
        May: '5',
        June: '6',
        July: '7',
        August: '8',
        September: '9',
        October: '10',
        November: '11',
        December: '12',
      },
      rating_lookup = {
        Any: '',
        '0-2 Rating': '0-2',
        '2-7 Rating': '2-7',
        '7-10 Rating': '7-10',
      },
      gender_lookup = {
        Any: '',
        Unspecified: 'Unspecified',
        Male: 'Male',
        Female: 'Female',
      },
      contact_lookup = {
        Any: '',
        'Has Phone': 'has_phone',
        'No Phone': 'no_phone',
        'Has Email': 'has_email',
        'No Email': 'no_email',
        'Has Phone/Email': 'has_phone_or_email',
        'No Phone/Email': 'no_phone_or_email',
      },
      venue_key = url_key,
      track_params = [
        'order',
        'q',
        'column',
        'filter_status',
        'filter_offset',
        'filter_visits',
        'filter_orders',
        'filter_birthday',
        'filter_gender',
        'filter_last_visit',
        'filter_last_order',
        'filter_bb_name',
        'filter_marketing_opt_in',
        'filter_marketing_opt_in_text',
        'filter_contact_info',
        'filter_rating',
        'filter_tags',
        'exclude_tags',
        'filter_venues',
        'filter_member_group',
        'any_client_tags',
      ],
      default_limit = 30

    const helpers = {
      WaitingQuip: function () {
        const quips = [
          'Wait for it...',
          'Please wait, Shoveling coal into the server...',
          "Please wait, At least you're not on hold...",
          'Please wait, The bits are flowing slowly today.',
          'Please wait, Follow the white rabbit...',
          'Please wait, Counting backwards from infinity...',
          'Entertaining you while you wait...',
          'Waiting for approval from Bill Gates...',
          'Waiting for Doc Brown to arrive...',
        ]
        const idx = Math.floor(Math.random() * (quips.length - 1))

        return quips[idx]
      },

      TagItem: React.createClass({
        render: function () {
          const style = {
            color: '#000',
            backgroundColor: this.props.tagColor,
          }

          const iconStyle = {
            color: 'black',
            paddingRight: '4px',
          }
          const checkIcon = this.props.checked ? String.fromCharCode(0xf973) : String.fromCharCode(0xf972)
          return (
            <div
              data-tag_group_id={this.props.groupId}
              data-name={this.props.groupName}
              data-tag={this.props.tagName}
              className="tag-item-container float-left new-style"
              onClick={this.props.onClick}
            >
              <div className="tag-item" style={style}>
                {this.props.checked !== undefined && (
                  <div className="tag-icon float-left" style={iconStyle}>
                    {checkIcon}
                  </div>
                )}
                {this.props.is_autotag && (
                  <div className="tag-icon float-left" style={iconStyle}>
                    {String.fromCharCode(0xf023)}
                  </div>
                )}
                <div className="tag-name float-left">{this.props.tagNameDisplay}</div>
              </div>
            </div>
          )
        },
      }),

      ClientCell: React.createClass({
        render: function () {
          const width = { client: 180, total_spend: 80 }[this.props.class]
          return (
            <a href={manager_base_url + '/clients/profile/' + this.props.vgcid}>
              <div
                key={this.props.key}
                style={{ width: width }}
                data-vgcid={this.props.vgcid}
                onClick={this.props.onClickCellHandler}
                className={'col col-' + this.props.class}
              >
                {this.props.value}
              </div>
            </a>
          )
        },
      }),

      CheckBox: React.createClass({
        render: function () {
          return (
            <div className="col col-merge">
              <div className={this.props.is_checked ? 'form-element checkbox checked' : 'form-element checkbox'}>
                <label htmlFor={this.props.id}>
                  <p className="input">
                    <input type="checkbox" onChange={this.props.onChangeHandler} checked={this.props.is_checked} id={this.props.id} />
                  </p>
                  <p className="label post" />
                </label>
              </div>
            </div>
          )
        },
      }),

      ClientRow: React.createClass({
        onClickHandler: function (event) {
          sessionStorage.setItem('search_url', window.location.pathname + window.location.search)
          const vgcid = $(event.currentTarget).data('vgcid')
        },

        render: function () {
          const columns = _.map(
              response_attributes,
              function (attr, index) {
                const val = this.props.client[attr],
                  property_pair = attributes_map[attr],
                  column_prop = property_pair['column'],
                  canonical_prop = property_pair['canonical']

                return (this.props.is_nightlife && attr === 'total_spend_per_visit_formatted') ||
                  (!this.props.is_nightlife && attr === 'total_spend_per_cover_formatted') ? (
                  ''
                ) : (
                  <helpers.ClientCell
                    key={index}
                    onClickCellHandler={this.onClickHandler}
                    vgcid={this.props.id}
                    class={column_prop || canonical_prop}
                    value={val}
                  />
                )
              },
              this
            ),
            applicableTagsDisplay = this.props.client.client_tags_display,
            tagsDisplay = applicableTagsDisplay.map(function (tag) {
              return (
                <helpers.TagItem
                  key={tag.tag_name}
                  is_autotag={tag.is_autotag}
                  tagColor={tag.tag_color}
                  tagName={tag.tag_name}
                  tagNameDisplay={tag.tag_name_display}
                />
              )
            }, this)
          return (
            <div className="client-row standard-row" data-vgcid={this.props.id}>
              <helpers.CheckBox
                onChangeHandler={this.props.clientToggleHandler}
                is_checked={this.props.is_checked}
                id={this.props.client.id}
              />
              {columns}
              {this.props.show_tags ? (
                <div className="tag-row">
                  <div className="tags-area-sortable">{tagsDisplay}</div>
                </div>
              ) : undefined}
            </div>
          )
        },
      }),

      ClientRows: React.createClass({
        render: function () {
          const clientRows = _.map(
            this.props.clients,
            function (client) {
              return (
                <helpers.ClientRow
                  key={client.id}
                  id={client.id}
                  client={client}
                  clientToggleHandler={this.props.clientToggleHandler}
                  is_checked={this.props.selected_client_ids.includes(client.id)}
                  show_tags={this.props.show_tags}
                  is_nightlife={this.props.is_nightlife}
                />
              )
            },
            this
          )

          return (
            <div className="list-block" id="clients-block">
              {clientRows}
            </div>
          )
        },
      }),

      ClientHeaderCell: React.createClass({
        getSorterHeight: function (sorter, order) {
          if (sorter === 'alphabetic' && order === 'ASCENDING') {
            return -7
          }
          if (sorter === 'alphabetic' && order === 'DESCENDING') {
            return -42
          }
          if (sorter === 'numeric' && order === 'ASCENDING') {
            return -75
          }
          if (sorter === 'numeric' && order === 'DESCENDING') {
            return -110
          }
          if (sorter === 'date' && order === 'ASCENDING') {
            return -143
          }
          if (sorter === 'date' && order === 'DESCENDING') {
            return -181
          }
        },

        onClickSort: function () {
          const order = {
            ASCENDING: 'DESCENDING',
            DESCENDING: 'ASCENDING',
          }[this.props.order || 'DESCENDING']
          this.props.onClickSort(this.props.canonical, order)
        },

        render: function () {
          const width = { client: 180, total_spend: 80 }[this.props.column]
          const styleObj = {
            width: width,
          }

          if (!this.props.sorter) {
            styleObj['cursor'] = 'unset'
          }
          return (
            <div
              key={this.props.key}
              className={'sorter col col-' + this.props.column}
              data-sort-key={this.props.sorter ? this.props.canonical : ''}
              data-sort-order={this.props.order}
              style={styleObj}
              onClick={this.props.sorter ? this.onClickSort : () => {}}
            >
              <span className={this.props.sorterClass}>
                {this.props.value}
                <img
                  style={
                    this.props.canonical === this.props.selectedColumn
                      ? {
                          top: this.getSorterHeight(this.props.sorter, this.props.order),
                        }
                      : {}
                  }
                  src={media_url + 'images/sorters.png'}
                />
              </span>
            </div>
          )
        },
      }),

      ClientHeader: React.createClass({
        render: function () {
          const clientHeaders = _.map(
            _.pairs(attributes_map),
            function (pair, index) {
              const attr = _.first(pair),
                property_pair = _.last(pair),
                display_prop = property_pair['display'],
                column_prop = property_pair['column'],
                sorter = property_pair['sorter'],
                canonical_prop = property_pair['canonical']

              return this.props.is_nightlife && attr === 'total_spend_per_visit_formatted' ? (
                ''
              ) : !this.props.is_nightlife && attr === 'total_spend_per_cover_formatted' ? (
                ''
              ) : (
                <helpers.ClientHeaderCell
                  key={index}
                  value={display_prop}
                  canonical={canonical_prop}
                  order={this.props.order}
                  selectedColumn={this.props.column}
                  sorter={sorter}
                  column={column_prop || canonical_prop}
                  onClickSort={this.props.onClickSort}
                />
              )
            },
            this
          )

          return (
            <div className="table-header">
              <div className="col col-merge" />
              {clientHeaders}
            </div>
          )
        },
      }),

      SearchFilter: React.createClass({
        onChangeHandler: function (e) {
          const selectedElement = $(e.currentTarget),
            selectedOption = $(selectedElement).find('option:selected'),
            filterName = $(selectedOption).attr('data-name'),
            filterValue = $(selectedElement).val()
          this.props.changeFilterValue(filterName, filterValue)
        },

        render: function () {
          const selectOptions = this.props.options.map(function (p, key) {
            return (
              <option value={p.id} key={key} data-name={p.filter_name}>
                {p.name}
              </option>
            )
          })

          return (
            <div className="form-element select" style={{ borderBottom: '1px dotted #ccc', overflow: 'auto' }}>
              <label>
                <p className="label">{this.props.label}</p>
                <p className="input">
                  <select className="filter_select" onChange={this.onChangeHandler} defaultValue={this.props.selectedValue}>
                    {selectOptions}
                  </select>
                  <span className="downer" />
                </p>
              </label>
            </div>
          )
        },
      }),

      SearchFilters: React.createClass({
        getInitialState: function () {
          return {
            show_filters: false,
            selected_filters: {},
          }
        },

        onClickHandler: function (e) {
          if (this.getDOMNode().contains(e.target)) {
            this.setState({ show_filters: true })
          } else {
            this.setState({ selected_filters: {}, show_filters: false })
          }
        },

        componentWillMount: function () {
          document.addEventListener('click', this.onClickHandler, false)
        },

        componentWillUnmount: function () {
          document.removeEventListener('click', this.onClickHandler, false)
        },

        changeFilterValue: function (filterName, filterValue) {
          this.state.selected_filters[filterName] = filterValue
        },

        applyFilters: function () {
          _.mapObject(
            this.state.selected_filters,
            function (filterValue, filterName) {
              this.props.changeFilterValue(filterName, filterValue)
            },
            this
          )
          this.props.changeFilterValue('filter_offset', 0)
          this.props.refetchData()
          this.setState({ selected_filters: {}, show_filters: false })
        },

        onCancelClick: function () {
          this.setState({ selected_filters: {}, show_filters: false })
        },

        render: function () {
          const booked_by_users_list = this.props.selectedFilters.booked_by_users.map(function (booked_name) {
              return {
                name: booked_name.name,
                filter_name: 'filter_bb_name',
                id: booked_name.name,
              }
            }),
            opt_in_venues_list = this.props.selectedFilters.venues.map(function (venue) {
              return {
                name: venue.name,
                filter_name: 'filter_marketing_opt_in',
                id: venue.id,
              }
            }),
            opt_in_venues_text_list = this.props.selectedFilters.venues
              .filter(venue => venue.is_sms_marketing_enabled)
              .map(function (venue) {
                return {
                  name: venue.name,
                  filter_name: 'filter_marketing_opt_in_text',
                  id: venue.id,
                }
              }),
            member_groups_list = this.props.selectedFilters.member_groups.map(function (member_group) {
              return {
                name: member_group.name,
                filter_name: 'filter_member_group',
                id: member_group.id,
              }
            })
          return (
            <span>
              <div className="filter-interface">
                <div className="show-filters">
                  Filters
                  <span className="downer" />
                </div>
                {this.state.show_filters ? (
                  <div>
                    <div className="filter-dropdown-container labels" style={{ display: 'block' }}>
                      <helpers.SearchFilter
                        selectedValue={this.props.selectedFilters.filter_status}
                        changeFilterValue={this.changeFilterValue}
                        label="VIP"
                        options={[
                          {
                            name: 'Any',
                            filter_name: 'filter_status',
                            id: status_lookup['Any'],
                          },
                          {
                            name: 'VIP',
                            filter_name: 'filter_status',
                            id: status_lookup['VIP'],
                          },
                        ]}
                      />
                      <helpers.SearchFilter
                        label="Visits"
                        selectedValue={this.props.selectedFilters.filter_visits}
                        changeFilterValue={this.changeFilterValue}
                        options={[
                          {
                            name: 'Any',
                            filter_name: 'filter_visits',
                            id: visits_lookup['Any'],
                          },
                          {
                            name: 'One visit',
                            filter_name: 'filter_visits',
                            id: visits_lookup['One visit'],
                          },
                          {
                            name: '1-10 visits',
                            filter_name: 'filter_visits',
                            id: visits_lookup['1-10 visits'],
                          },
                          {
                            name: '10+ visits',
                            filter_name: 'filter_visits',
                            id: visits_lookup['10+ visits'],
                          },
                        ]}
                      />
                      <helpers.SearchFilter
                        label="Last visit"
                        selectedValue={this.props.selectedFilters.filter_last_visit}
                        changeFilterValue={this.changeFilterValue}
                        options={[
                          {
                            name: 'Any',
                            filter_name: 'filter_last_visit',
                            id: last_visit_lookup['Any'],
                          },
                          {
                            name: 'Last Month',
                            filter_name: 'filter_last_visit',
                            id: last_visit_lookup['Last Month'],
                          },
                          {
                            name: 'Last 3 Months',
                            filter_name: 'filter_last_visit',
                            id: last_visit_lookup['Last 3 Months'],
                          },
                          {
                            name: 'Last 6 Months',
                            filter_name: 'filter_last_visit',
                            id: last_visit_lookup['Last 6 Months'],
                          },
                        ]}
                      />
                      {show_order_stats && (
                        <helpers.SearchFilter
                          label="Orders"
                          selectedValue={this.props.selectedFilters.filter_orders}
                          changeFilterValue={this.changeFilterValue}
                          options={[
                            {
                              name: 'Any',
                              filter_name: 'filter_orders',
                              id: orders_lookup['Any'],
                            },
                            {
                              name: 'One order',
                              filter_name: 'filter_orders',
                              id: orders_lookup['One order'],
                            },
                            {
                              name: '1-10 orders',
                              filter_name: 'filter_orders',
                              id: orders_lookup['1-10 orders'],
                            },
                            {
                              name: '10+ orders',
                              filter_name: 'filter_orders',
                              id: orders_lookup['10+ orders'],
                            },
                          ]}
                        />
                      )}
                      {show_order_stats && (
                        <helpers.SearchFilter
                          label="Last order"
                          selectedValue={this.props.selectedFilters.filter_last_order}
                          changeFilterValue={this.changeFilterValue}
                          options={[
                            {
                              name: 'Any',
                              filter_name: 'filter_last_order',
                              id: last_order_lookup['Any'],
                            },
                            {
                              name: 'Last Month',
                              filter_name: 'filter_last_order',
                              id: last_order_lookup['Last Month'],
                            },
                            {
                              name: 'Last 3 Months',
                              filter_name: 'filter_last_order',
                              id: last_order_lookup['Last 3 Months'],
                            },
                            {
                              name: 'Last 6 Months',
                              filter_name: 'filter_last_order',
                              id: last_order_lookup['Last 6 Months'],
                            },
                          ]}
                        />
                      )}
                      <helpers.SearchFilter
                        label="Birthday"
                        selectedValue={this.props.selectedFilters.filter_birthday}
                        changeFilterValue={this.changeFilterValue}
                        options={[
                          {
                            name: 'Any',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['Any'],
                          },
                          {
                            name: 'January',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['January'],
                          },
                          {
                            name: 'February',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['February'],
                          },
                          {
                            name: 'March',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['March'],
                          },
                          {
                            name: 'April',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['April'],
                          },
                          {
                            name: 'May',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['May'],
                          },
                          {
                            name: 'June',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['June'],
                          },
                          {
                            name: 'July',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['July'],
                          },
                          {
                            name: 'August',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['August'],
                          },
                          {
                            name: 'September',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['September'],
                          },
                          {
                            name: 'October',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['October'],
                          },
                          {
                            name: 'November',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['November'],
                          },
                          {
                            name: 'December',
                            filter_name: 'filter_birthday',
                            id: birthday_lookup['December'],
                          },
                        ]}
                      />
                      <helpers.SearchFilter
                        label="Rating"
                        selectedValue={this.props.selectedFilters.filter_rating}
                        changeFilterValue={this.changeFilterValue}
                        options={[
                          {
                            name: 'Any',
                            filter_name: 'filter_rating',
                            id: rating_lookup['Any'],
                          },
                          {
                            name: '0-2 Rating',
                            filter_name: 'filter_rating',
                            id: rating_lookup['0-2 Rating'],
                          },
                          {
                            name: '2-7 Rating',
                            filter_name: 'filter_rating',
                            id: rating_lookup['2-7 Rating'],
                          },
                          {
                            name: '7-10 Rating',
                            filter_name: 'filter_rating',
                            id: rating_lookup['7-10 Rating'],
                          },
                        ]}
                      />
                      <helpers.SearchFilter
                        label="Gender"
                        changeFilterValue={this.changeFilterValue}
                        selectedValue={this.props.selectedFilters.filter_gender}
                        options={[
                          {
                            name: 'Any',
                            filter_name: 'filter_gender',
                            id: gender_lookup['Any'],
                          },
                          {
                            name: 'Unspecified',
                            filter_name: 'filter_gender',
                            id: gender_lookup['Unspecified'],
                          },
                          {
                            name: 'Male',
                            filter_name: 'filter_gender',
                            id: gender_lookup['Male'],
                          },
                          {
                            name: 'Female',
                            filter_name: 'filter_gender',
                            id: gender_lookup['Female'],
                          },
                        ]}
                      />
                      <helpers.SearchFilter
                        selectedValue={this.props.selectedFilters.filter_contact_info}
                        label="Contact Info"
                        changeFilterValue={this.changeFilterValue}
                        options={[
                          {
                            name: 'Any',
                            filter_name: 'filter_contact_info',
                            id: contact_lookup['Any'],
                          },
                          {
                            name: 'Has Phone',
                            filter_name: 'filter_contact_info',
                            id: contact_lookup['Has Phone'],
                          },
                          {
                            name: 'No Phone',
                            filter_name: 'filter_contact_info',
                            id: contact_lookup['No Phone'],
                          },
                          {
                            name: 'Has Email',
                            filter_name: 'filter_contact_info',
                            id: contact_lookup['Has Email'],
                          },
                          {
                            name: 'No Email',
                            filter_name: 'filter_contact_info',
                            id: contact_lookup['No Email'],
                          },
                          {
                            name: 'Has Phone/Email',
                            filter_name: 'filter_contact_info',
                            id: contact_lookup['Has Phone/Email'],
                          },
                          {
                            name: 'No Phone/Email',
                            filter_name: 'filter_contact_info',
                            id: contact_lookup['No Phone/Email'],
                          },
                        ]}
                      />
                      {member_groups_list.length > 0 && (
                        <helpers.SearchFilter
                          label="Member Group"
                          selectedValue={this.props.selectedFilters.filter_member_group}
                          changeFilterValue={this.changeFilterValue}
                          options={_.union(
                            [
                              {
                                name: 'Any',
                                filter_name: 'filter_member_group',
                                id: '',
                              },
                            ],
                            member_groups_list
                          )}
                        />
                      )}
                      <helpers.SearchFilter
                        label="Booked By"
                        selectedValue={this.props.selectedFilters.filter_bb_name}
                        changeFilterValue={this.changeFilterValue}
                        options={_.union(
                          [
                            {
                              name: 'Any',
                              filter_name: 'filter_bb_name',
                              id: '',
                            },
                          ],
                          booked_by_users_list
                        )}
                      />
                      <helpers.SearchFilter
                        label="Marketing Opt In (Email)"
                        selectedValue={this.props.selectedFilters.filter_marketing_opt_in}
                        changeFilterValue={this.changeFilterValue}
                        options={_.union(
                          [
                            {
                              name: 'Off',
                              filter_name: 'filter_marketing_opt_in',
                              id: '',
                            },
                          ],
                          opt_in_venues_list
                        )}
                      />
                      {is_sms_marketing_enabled && (
                        <helpers.SearchFilter
                          label="Marketing Opt In (Text)"
                          selectedValue={this.props.selectedFilters.filter_marketing_opt_in_text}
                          changeFilterValue={this.changeFilterValue}
                          options={_.union(
                            [
                              {
                                name: 'Off',
                                filter_name: 'filter_marketing_opt_in_text',
                                id: '',
                              },
                            ],
                            opt_in_venues_text_list
                          )}
                        />
                      )}
                      <div className="filter-submit">
                        <p className="button" onClick={this.applyFilters}>
                          <a href="javascript:void(0);" id="client-filters-submit">
                            Apply
                          </a>
                        </p>
                        <p className="button plain" onClick={this.onCancelClick}>
                          <a id="client-filter-nevermind" className="filter-nevermind-link" href="javascript:void(0);">
                            nevermind
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : undefined}
              </div>
            </span>
          )
        },
      }),

      TaggroupFilter: React.createClass({
        render: function () {
          const matching_tags = _.filter(
            this.props.tags,
            function (tag) {
              const tagDisplay = this.props.tag_name_displays[tag] || tag
              return tagDisplay.toLowerCase().includes(this.props.search_text.toLowerCase())
            },
            this
          )
          const tags = matching_tags.map(function (tag) {
            const tagDisplay = this.props.tag_name_displays[tag] || tag
            const checked = this.props.selected && this.props.selected?.includes(tag)
            return (
              <helpers.TagItem
                key={this.props.tag_group_id + tag}
                is_autotag={this.props.is_autotag}
                groupName={this.props.name}
                groupId={this.props.tag_group_id}
                tagColor={this.props.color}
                tagName={tag}
                tagNameDisplay={tagDisplay}
                onClick={this.props.onClickHandler}
                checked={checked}
              />
            )
          }, this)
          return _.size(tags) ? (
            <div style={{ clear: 'both', padding: '2px 0px 5px 0px' }}>
              <div
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  padding: 5,
                }}
              >
                {this.props.name_display}
              </div>
              {tags}
            </div>
          ) : (
            <span />
          )
        },
      }),

      VenuesFilter: React.createClass({
        getInitialState: function () {
          const location = window.location.href
          return {
            show_filters: false,
            search_text: '',
            venue_name: location.substring(location.indexOf('manager') + 8, location.lastIndexOf('clients') - 1),
          }
        },

        onClickHandler: function (e) {
          this.setState({ show_filters: this.getDOMNode().contains(e.target) })
        },

        onVenueClickHandler: function (e) {
          this.setState({ show_filters: false, search_text: '' })
          this.props.onClickHandler(e)
        },

        componentWillMount: function () {
          document.addEventListener('click', this.onClickHandler, false)
        },

        componentWillUnmount: function () {
          document.removeEventListener('click', this.onClickHandler, false)
        },

        componentDidMount: function () {
          const $merge_selected_btn = $('#merge-selected-btn')
          $merge_selected_btn.on('mouseover', function (event) {
            if ($(this).hasClass('disabled')) {
              $('#merge-help').show()
            }
          })
          $merge_selected_btn.on('mouseout', function (event) {
            $('#merge-help').hide()
          })
        },

        onChangeHandler: function (e) {
          const searchText = $(e.currentTarget).val()
          this.setState({ search_text: searchText })
        },

        render: function () {
          return (
            <span>
              <div className="filter-interface">
                <div className="show-filters">
                  Venues
                  <span className="downer" />
                </div>
              </div>
              {this.state.show_filters ? (
                <div
                  style={{
                    position: 'absolute',
                    width: 250,
                    zIndex: 100,
                    left: 317,
                    padding: 10,
                    background: 'white',
                    border: '1px solid #e1e1e1',
                    top: 40,
                  }}
                  className="filter-dd filter-dropdown"
                >
                  <input type="text" defaultValue={this.state.search_text} onChange={this.onChangeHandler} />
                  <helpers.TaggroupFilter
                    key="venue-group"
                    name=""
                    is_autotag={false}
                    name_display=""
                    tag_name_displays=""
                    search_text={this.state.search_text}
                    tag_group_id=""
                    onClickHandler={this.onVenueClickHandler}
                    color=""
                    tags={this.props.selectedFilters.venues.map(i => i.name)}
                    selected={this.props.selectedFilters.venues
                      .filter(i => this.props.selectedFilters.filter_venues?.includes(i.id))
                      .map(i => i.name)}
                  />
                </div>
              ) : undefined}
            </span>
          )
        },
      }),

      TagsFilter: React.createClass({
        getInitialState: function () {
          return {
            show_filters: false,
            search_text: '',
            any_checkbox: this.props.selectedFilters.any_client_tags,
          }
        },

        onClickHandler: function (e) {
          this.setState({ show_filters: this.getDOMNode().contains(e.target) })
        },

        onTagClickHandler: function (e) {
          this.setState({ show_filters: false, search_text: '' })
          this.props.onClickHandler(e)
        },

        componentWillMount: function () {
          document.addEventListener('click', this.onClickHandler, false)
        },

        componentWillUnmount: function () {
          document.removeEventListener('click', this.onClickHandler, false)
        },

        componentDidMount: function () {
          const $merge_selected_btn = $('#merge-selected-btn')
          $merge_selected_btn.on('mouseover', function (event) {
            if ($(this).hasClass('disabled')) {
              $('#merge-help').show()
            }
          })
          $merge_selected_btn.on('mouseout', function (event) {
            $('#merge-help').hide()
          })
        },

        onChangeHandler: function (e) {
          const searchText = $(e.currentTarget).val()
          this.setState({ search_text: searchText })
        },

        onAllClientHandler: function (e) {
          this.setState({ any_checkbox: false, show_filters: false })
          this.props.onAllOrAnyTagClickHandler(false)
        },

        onAnyClientClickHandler: function (e) {
          this.setState({ any_checkbox: true, show_filters: false })
          this.props.onAllOrAnyTagClickHandler(true)
        },

        render: function () {
          const tags_rendered = this.props.selectedFilters.tag_groups.map(function (tag_group) {
            let tags = tag_group.tags
            tag_group.tags.forEach(tag => {
              const tagId = tag_group.id + '##' + tag_group.name + '##' + tag
              if (this.props.selectedFilters.exclude_tags && this.props.selectedFilters.exclude_tags.includes(tagId)) {
                tags = tags.filter(element => element !== tag)
              }
            })
            return (
              <helpers.TaggroupFilter
                key={tag_group.id}
                name={tag_group.name}
                is_autotag={tag_group.is_autotag}
                name_display={tag_group.name_display || tag_group.name}
                tag_name_displays={tag_group.tag_name_displays}
                search_text={this.state.search_text}
                tag_group_id={tag_group.id}
                onClickHandler={this.onTagClickHandler}
                color={tag_group.color_hex}
                tags={tags}
              />
            )
          }, this)

          return (
            <span>
              <div className="filter-interface">
                <div className="show-filters">
                  Tags
                  <span className="downer" />
                </div>
              </div>
              {this.state.show_filters ? (
                <div
                  style={{
                    position: 'absolute',
                    width: 250,
                    zIndex: 100,
                    left: 317,
                    padding: 10,
                    background: 'white',
                    border: '1px solid #e1e1e1',
                    top: 40,
                  }}
                  className="filter-dd filter-dropdown"
                >
                  <input type="text" defaultValue={this.state.search_text} onChange={this.onChangeHandler} />
                  <div style={{ clear: 'both', padding: '5px', fontSize: '14px', fontWeight: 600 }}>
                    <input
                      type="radio"
                      id="all-client-tag"
                      checked={this.state.any_checkbox === false}
                      onChange={this.onAllClientHandler}
                    />{' '}
                    All Tags
                  </div>
                  <div style={{ clear: 'both', padding: '5px', fontSize: '14px', fontWeight: 600 }}>
                    <input
                      type="radio"
                      id="any-client-tag"
                      checked={this.state.any_checkbox === true}
                      onChange={this.onAnyClientClickHandler}
                    />{' '}
                    Any Tags
                  </div>
                  {tags_rendered}
                </div>
              ) : undefined}
            </span>
          )
        },
      }),

      ExcludeTagsFilter: React.createClass({
        getInitialState: function () {
          return {
            show_filters: false,
            search_text: '',
            any_checkbox: this.props.selectedFilters.any_client_tags,
          }
        },

        onClickHandler: function (e) {
          this.setState({ show_filters: this.getDOMNode().contains(e.target) })
        },

        onTagClickHandler: function (e) {
          this.setState({ show_filters: false, search_text: '' })
          this.props.onClickHandler(e)
        },

        componentWillMount: function () {
          document.addEventListener('click', this.onClickHandler, false)
        },

        componentWillUnmount: function () {
          document.removeEventListener('click', this.onClickHandler, false)
        },

        componentDidMount: function () {
          const $merge_selected_btn = $('#merge-selected-btn')
          $merge_selected_btn.on('mouseover', function (event) {
            if ($(this).hasClass('disabled')) {
              $('#merge-help').show()
            }
          })
          $merge_selected_btn.on('mouseout', function (event) {
            $('#merge-help').hide()
          })
        },

        onChangeHandler: function (e) {
          const searchText = $(e.currentTarget).val()
          this.setState({ search_text: searchText })
        },

        render: function () {
          const tags_rendered = this.props.selectedFilters.tag_groups.map(function (tag_group) {
            let tags = tag_group.tags
            tag_group.tags.forEach(tag => {
              const tagId = tag_group.id + '##' + tag_group.name + '##' + tag
              if (this.props.selectedFilters.filter_tags && this.props.selectedFilters.filter_tags.includes(tagId)) {
                tags = tags.filter(element => element !== tag)
              }
            })

            return (
              <helpers.TaggroupFilter
                key={tag_group.id}
                name={tag_group.name}
                is_autotag={tag_group.is_autotag}
                name_display={tag_group.name_display || tag_group.name}
                tag_name_displays={tag_group.tag_name_displays}
                search_text={this.state.search_text}
                tag_group_id={tag_group.id}
                onClickHandler={this.onTagClickHandler}
                color={tag_group.color_hex}
                tags={tags}
              />
            )
          }, this)
          return (
            <span>
              <div className="filter-interface">
                <div className="show-filters">
                  Exclude Tag
                  <span className="downer" />
                </div>
              </div>
              {this.state.show_filters ? (
                <div
                  style={{
                    position: 'absolute',
                    width: 250,
                    zIndex: 100,
                    left: 425,
                    padding: 10,
                    background: 'white',
                    border: '1px solid #e1e1e1',
                    top: 40,
                  }}
                  className="filter-dd filter-dropdown"
                >
                  <input type="text" defaultValue={this.state.search_text} onChange={this.onChangeHandler} />
                  {tags_rendered}
                </div>
              ) : undefined}
            </span>
          )
        },
      }),

      filtersSelection: React.createClass({
        render: function () {
          const values = Array.isArray(this.props.value) ? this.props.value : [this.props.value]
          // lookup is a list of pairs (key, value) and we are searching for key where value matches
          return (
            <div>
              {values.map(value => {
                var selected_value =
                  (_.filter(
                    this.props.lookup,
                    function (a) {
                      return a[1] === value
                    },
                    this
                  )[0] || [])[0] || ''

                return (
                  <div
                    style={{
                      backgroundColor: this.props.color || '#EEE',
                      float: 'left',
                      marginRight: 5,
                      borderRadius: 3,
                    }}
                    key={value}
                  >
                    <div
                      style={{
                        float: 'left',
                        padding: '1px 5px',
                        fontSize: 11,
                      }}
                    >
                      {this.props.label + ' : ' + selected_value}
                    </div>
                    <div
                      style={{
                        float: 'left',
                        padding: '1px 5px',
                        fontSize: 13,
                        backgroundColor: '#CCC',
                        width: '15',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={_.partial(this.props.onDelete, this.props.name, value)}
                    >
                      x
                    </div>
                  </div>
                )
              })}
            </div>
          )
        },
      }),

      ErrorPopup: React.createClass({
        getInitialState: function () {
          return {
            err: this.props.message,
          }
        },
        render: function () {
          return create_popup(this, 'Error', this.state.err, <span />, 'Close')
        },
      }),

      EmmaExportPopup: React.createClass({
        codeToException: function (status) {
          if (status >= 500) {
            throw Error('connection_error')
          } else if (status >= 400) {
            throw Error('auth_error')
          }
        },
        getInitialState: function () {
          fetch('clients/emma_groups')
            .then(r => {
              if (!r.ok) {
                this.codeToException(r.status)
              }
              return r
            })
            .then(r => r.json())
            .then(r => {
              this.setState({ groups: r.payload.groups.sort((a, b) => a[1].localeCompare(b[1])) })
              if (this.state.groups.length) {
                this.props.onGroupSelect(this.state.groups[0][0])
                this.setState({ emma_group_name: this.state.groups[0][1] })
              } else this.setState({ step: 2, err: 'no_groups' })
            })
            .catch(err => this.setState({ err: err.message }))

          return {
            err: undefined,
            groups: undefined,
            step: 0,
            emma_group_name: undefined,
          }
        },

        onExportHandler: function () {
          this.setState({ step: 2 })
          this.props.onExportHandler()
        },

        waiting: function () {
          return (
            <div style={{ textAlign: 'center' }}>
              <img src={`${Pmp.Settings.MEDIA_URL}images/spinner.gif`} />
            </div>
          )
        },

        groupSelect: function (has_marketing_filter) {
          const warning = !has_marketing_filter ? (
            <div className="error">
              *Note: It does not look like you have filtered for Marketing Opt-in. If you would like to only send clients that have opted-in
              to marketing emails, please update your filters before proceeding.
            </div>
          ) : (
            ''
          )

          return (
            <form className="styled">
              <div className="form-element select">
                {warning}
                <p className="label pre">
                  <span>Choose an existing group</span>
                </p>
                <p className="input" style={{ display: 'inline-block', width: '250px' }}>
                  <select
                    onChange={e => {
                      const selectedOption = $(e.target).find('option:selected').text()
                      this.setState({ emma_group_name: selectedOption })
                      this.props.onGroupSelect(e.target.value, selectedOption)
                    }}
                  >
                    {this.state.groups.map((e, i) => {
                      return <option value={e[0]}>{e[1]}</option>
                    })}
                  </select>
                  <span className="downer" />
                </p>
              </div>
            </form>
          )
        },

        render: function () {
          let message = undefined

          if (this.state.err !== undefined) {
            if (this.state.err === 'no_groups') message = 'No groups found. Please create a group in your Emma account and try again.'
            else if (this.state.err === 'connection_error')
              message = 'We’re having trouble connecting to Emma. Please email support@sevenrooms.com for assistance.'
            else if (this.state.err === 'auth_error')
              message = 'It looks like your Emma API key has been reset. Please disconnect Emma and reconfigure the integration.'
            else message = 'Unrecognised errror.'
          } else {
            if (this.state.step === 0) message = this.state.groups ? this.groupSelect(this.props.has_marketing_filter) : this.waiting()
            else if (this.state.step === 1)
              message = `Are you sure? Press confirm to update ${this.state.emma_group_name} in Emma. Note, the number exported may be fewer than indicated.`
            else
              message = (
                <span>Your request is being processed. You will receive an email notification at {email} when the export is complete.</span>
              )
          }

          let button = undefined
          if (this.state.step === 0 && this.state.groups) {
            button = (
              <p className="button" onClick={() => this.setState({ step: 1 })}>
                <a id="export-btn" href="javascript:void(0);">
                  Next
                </a>
              </p>
            )
          } else if (this.state.step === 1) {
            button = (
              <p className="button" onClick={this.onExportHandler}>
                <a id="export-btn" href="javascript:void(0);">
                  Confirm
                </a>
              </p>
            )
          } else {
            button = <span />
          }

          return create_popup(this, 'Emma Integration', message, button, this.state.step === 2 ? 'Close' : 'Cancel')
        },
      }),

      MailchimpExportPopup: React.createClass({
        codeToException: function (status) {
          if (status >= 500) {
            throw Error('connection_error')
          } else if (status >= 400) {
            throw Error('auth_error')
          }
        },

        getInitialState: function () {
          this.fetchAudiences()

          return {
            err: undefined,
            audiences: undefined,
            groups: undefined,
            step: 0,
            mailchimp_audience_name: undefined,
            mailchimp_audience_id: undefined,
            mailchimp_group_name: undefined,
            mailchimp_group_id: undefined,
          }
        },

        fetchAudiences: function () {
          fetch('clients/mailchimp_audiences')
            .then(r => {
              if (!r.ok) {
                this.codeToException(r.status)
              }
              return r
            })
            .then(r => r.json())
            .then(r => {
              this.setState({ audiences: r.payload.audiences.sort((a, b) => a[1].localeCompare(b[1])) })
              if (this.state.audiences.length) {
                this.props.onAudienceSelect(this.state.audiences[0][0])
                this.setState({
                  mailchimp_audience_name: this.state.audiences[0][1],
                  mailchimp_audience_id: this.state.audiences[0][0],
                })
              } else this.setState({ err: 'no_audiences', step: 3 })
            })
            .catch(err => this.setState({ err: 'connection', step: 3 }))
        },

        fetchGroups: function () {
          const data = new FormData()
          data.append('audience_id', this.state.mailchimp_audience_id)

          fetch('clients/mailchimp_groups', {
            method: 'POST',
            body: data,
          })
            .then(r => {
              if (!r.ok) {
                this.codeToException(r.status)
              }
              return r
            })
            .then(r => r.json())
            .then(r => {
              this.setState({ groups: r.payload.groups.sort((a, b) => a[1].localeCompare(b[1])) })
              if (this.state.groups.length) {
                this.props.onGroupSelect(this.state.groups[0][0])
                this.setState({
                  mailchimp_group_name: this.state.groups[0][1],
                  mailchimp_group_id: this.state.groups[0][0],
                })
                this.setState({ step: 1 })
              } else this.setState({ step: 2 })
            })
            .catch(err => {
              this.setState({ err: 'connection', step: 3 })
            })
        },

        onExportHandler: function () {
          this.setState({ step: 3 })
          this.props.onExportHandler()
        },

        waiting: function () {
          return (
            <div style={{ textAlign: 'center' }}>
              <img src={`${Pmp.Settings.MEDIA_URL}images/spinner.gif`} />
            </div>
          )
        },

        groupSelect: function (has_marketing_filter) {
          const warning = !has_marketing_filter ? (
            <div className="error">
              *Note: It does not look like you have filtered for Marketing Opt-in. If you would like to only send clients that have opted-in
              to marketing emails, please update your filters before proceeding.
            </div>
          ) : (
            ''
          )

          return (
            <form className="styled">
              <div className="form-element select">
                {warning}
                <p className="label pre">
                  <span>Choose a group for this audience</span>
                </p>
                <p className="input" style={{ display: 'inline-block', width: '250px' }}>
                  <select
                    onChange={e => {
                      const selectedOption = $(e.target).find('option:selected').text()
                      this.setState({
                        mailchimp_group_name: selectedOption,
                        mailchimp_group_id: e.target.value,
                      })
                      this.props.onGroupSelect(e.target.value, selectedOption)
                    }}
                  >
                    {this.state.groups.map((e, i) => {
                      return <option value={e[0]}>{e[1]}</option>
                    })}
                  </select>
                  <span className="downer" />
                </p>
              </div>
            </form>
          )
        },

        audienceSelect: function (has_marketing_filter) {
          const warning = !has_marketing_filter ? (
            <div className="error">
              *Note: It does not look like you have filtered for Marketing Opt-in. If you would like to only send clients that have opted-in
              to marketing emails, please update your filters before proceeding.
            </div>
          ) : (
            ''
          )

          return (
            <form className="styled">
              <div className="form-element select">
                {warning}
                <p className="label pre">
                  <span>Choose an existing audience</span>
                </p>
                <p className="input" style={{ display: 'inline-block', width: '250px' }}>
                  <select
                    onChange={e => {
                      const selectedOption = $(e.target).find('option:selected').text()
                      this.setState({ mailchimp_audience_name: selectedOption, mailchimp_audience_id: e.target.value })
                      this.props.onAudienceSelect(e.target.value, selectedOption)
                    }}
                  >
                    {this.state.audiences.map((e, i) => {
                      return <option value={e[0]}>{e[1]}</option>
                    })}
                  </select>
                  <span className="downer" />
                </p>
              </div>
            </form>
          )
        },

        render: function () {
          let message = undefined

          if (this.state.err !== undefined) {
            if (this.state.err === 'no_audiences')
              message = 'No audiences found. Please create an audience in your Mailchimp account and try again.'
            else if (this.state.err === 'connection')
              message = "We're having trouble connecting to Mailchimp. Please email support@sevenrooms.com for assistance."
            else message = 'Unrecognised errror.'
          } else {
            if (this.state.step === 0)
              message = this.state.audiences ? this.audienceSelect(this.props.has_marketing_filter) : this.waiting()
            else if (this.state.step === 1) message = this.state.groups ? this.groupSelect(this.props.has_marketing_filter) : this.waiting()
            else if (this.state.step === 2)
              message = `Are you sure? Press confirm to update ${
                this.state.mailchimp_group_name || this.state.mailchimp_audience_name
              } in Mailchimp. Note, the number exported may be fewer than indicated.`
            else
              message = (
                <span>Your request is being processed. You will receive an email notification at {email} when the export is complete.</span>
              )
          }

          let button = undefined
          if (this.state.step === 0 && this.state.audiences) {
            button = (
              <p
                className="button"
                onClick={() => {
                  this.fetchGroups()
                }}
              >
                <a id="export-btn" href="javascript:void(0);">
                  Next
                </a>
              </p>
            )
          } else if (this.state.step == 1 && this.state.groups) {
            button = (
              <p className="button" onClick={() => this.setState({ step: 2 })}>
                <a id="export-btn" href="javascript:void(0);">
                  Next
                </a>
              </p>
            )
          } else if (this.state.step === 2) {
            button = (
              <p className="button" onClick={this.onExportHandler}>
                <a id="export-btn" href="javascript:void(0);">
                  Confirm
                </a>
              </p>
            )
          } else {
            button = <span />
          }

          return create_popup(this, 'Mailchimp Integration', message, button, this.state.step === 3 ? 'Close' : 'Cancel')
        },
      }),

      ExcelExportPopup: React.createClass({
        getInitialState: function () {
          return {
            is_export_on: false,
          }
        },

        onExportHandler: function () {
          this.setState({ is_export_on: true })
          this.props.onExportHandler()
        },

        render: function () {
          const message = !this.state.is_export_on ? (
            <span>
              Exporting client profiles may take several minutes. Click export and we'll email a download link to {email} when it's ready.
            </span>
          ) : (
            <span>Your request is being processed. You will receive an email notification at {email} when the export is complete.</span>
          )
          const button = !this.state.is_export_on ? (
            <p className="button" onClick={this.onExportHandler}>
              <a id="export-btn" href="javascript:void(0);">
                Export
              </a>
            </p>
          ) : (
            <span />
          )

          return create_popup(this, 'Client Profile Export', message, button, this.state.is_export_on ? 'Done' : 'Cancel')
        },
      }),

      InviteClientPopup: React.createClass({
        getInitialState: function () {
          return {
            invite_clients_to_app_on: false,
          }
        },

        onInviteClientsToAppHandler: function () {
          this.setState({ invite_clients_to_app_on: true })
          this.props.onInviteClientsToAppHandler()
        },

        render: function () {
          const numOfClientsToInvite =
            this.props.selectedClientIds.length > 0 ? this.props.selectedClientIds.length : this.props.numOfResults
          const clientStr = numOfClientsToInvite > 1 ? 'clients' : 'client'
          const message = !this.state.invite_clients_to_app_on ? (
            <span>
              You are granting {numOfClientsToInvite} {clientStr} access to the {venue_name} Room in the SevenRooms App. This may take a few
              minutes to process.
            </span>
          ) : (
            <span>Your request is being processed. Clients will be given access to your Room in the SevenRooms app.</span>
          )
          const button = !this.state.invite_clients_to_app_on ? (
            <p className="button" onClick={this.onInviteClientsToAppHandler}>
              <a id="invite-clients-to-app-btn" href="javascript:void(0);">
                Grant Access
              </a>
            </p>
          ) : (
            <span />
          )
          return create_popup(
            this,
            'Grant Clients Access to SevenRooms App',
            message,
            button,
            this.state.invite_clients_to_app_on ? 'Done' : 'Cancel',
            'invite-cancel-btn'
          )
        },
      }),

      Table: React.createClass({
        getInitialState: function () {
          return {
            column: 'total_visits',
            order: 'DESCENDING',
            q: this.props.q,
            post_q: this.props.q,
            filter_status: this.props.filter_status,
            filter_offset: this.props.filter_offset,
            filter_visits: this.props.filter_visits,
            filter_orders: this.props.filter_orders,
            filter_birthday: this.props.filter_birthday,
            filter_gender: this.props.filter_gender,
            filter_last_visit: this.props.filter_last_visit,
            filter_last_order: this.props.filter_last_order,
            filter_bb_name: this.props.filter_bb_name,
            filter_marketing_opt_in: this.props.filter_marketing_opt_in,
            filter_marketing_opt_in_text: this.props.filter_marketing_opt_in_text,
            filter_contact_info: this.props.filter_contact_info,
            filter_rating: this.props.filter_rating,
            filter_tags: this.props.filter_tags,
            exclude_tags: this.props.exclude_tags,
            filter_venues: new URLSearchParams(window.location.search).getAll('filter_venues[]'),
            filter_member_group: this.props.filter_member_group,
            clients: [],
            facets: [],
            venues: this.props.venue_list_dict,
            tag_groups: this.props.tag_groups,
            booked_by_users: this.props.bb_users,
            member_groups: this.props.member_groups,
            selected_client_ids: [],
            show_tags: false,
            num_found: 0,
            should_fetch_data: true,
            is_fetch_in_progress: false,
            popped_state: false,
            is_nightlife: false,
            select_all: false,
            format: undefined,
            inviteClientToApp: false,
            is_first_load: true,
            any_client_tags: this.props.any_client_tags,
            error_message: error_message,
          }
        },

        componentWillMount: function () {
          window.addEventListener('popstate', this.onPopStateHandler, false)
          this.fetchData()
          $('#manager-ajax').show()
        },

        componentDidMount: function () {
          $(this.getDOMNode())
            .find('form')
            .bind('keypress', function (e) {
              if (e.which === 13) {
                return false
              }
            })

          $('.export-link-container .excel-button').on(
            'click',
            _.bind(function () {
              this.setState({ format: 'excel' })
            }, this)
          )

          $('.export-link-container .emma-button').on(
            'click',
            _.bind(function () {
              this.setState({ format: 'emma' })
            }, this)
          )

          $('.export-link-container .mailchimp-button').on(
            'click',
            _.bind(function () {
              this.setState({ format: 'mailchimp' })
            }, this)
          )

          $('#invite-clients-to-app').on(
            'click',
            _.bind(function () {
              this.setState({ inviteClientToApp: true })
            }, this)
          )
        },

        getParams: function () {
          var filter_tags = []
          var exclude_tags = []
          if (!_.isEmpty(this.state.filter_tags)) {
            var escapedTags = _.map(this.state.filter_tags, function (o) {
              return o
            })
            filter_tags = escapedTags.join(',')
          }
          if (!_.isEmpty(this.state.exclude_tags)) {
            var escapedExcludeTags = _.map(this.state.exclude_tags, function (o) {
              return o
            })
            exclude_tags = escapedExcludeTags.join(',')
          }
          const filterVenues = this.state.filter_venues
            ? this.state.filter_venues
            : new URLSearchParams(window.location.search).getAll('filter_venues[]')

          var filters = _.extend(
            this.state.filter_status ? { status: this.state.filter_status } : {},
            this.state.filter_visits ? { visits: this.state.filter_visits } : {},
            this.state.filter_orders ? { orders: this.state.filter_orders } : {},
            this.state.filter_birthday ? { birthday_month: this.state.filter_birthday } : {},
            this.state.filter_rating ? { rating: this.state.filter_rating } : {},
            this.state.filter_gender ? { gender: this.state.filter_gender } : {},
            this.state.filter_last_visit ? { last_visit: this.state.filter_last_visit } : {},
            this.state.filter_last_order ? { last_order: this.state.filter_last_order } : {},
            this.state.filter_member_group ? { filter_member_group: this.state.filter_member_group } : {},
            !_.isEmpty(filter_tags) ? { filter_client_tags: filter_tags } : {},
            !_.isEmpty(exclude_tags) ? { exclude_tags } : {},
            filter_member_group ? { filter_member_group: filter_member_group } : {},
            this.state.filter_venues ? { filter_venues: filterVenues } : {},
            this.state.filter_contact_info ? { contact_info: this.state.filter_contact_info } : {},
            this.state.filter_bb_name ? { bb_name: this.state.filter_bb_name } : {},
            this.state.filter_marketing_opt_in ? { marketing_opt_in: this.state.filter_marketing_opt_in } : {},
            this.state.filter_marketing_opt_in_text ? { marketing_opt_in_text: this.state.filter_marketing_opt_in_text } : {},
            {}
          )
          return _.extend(
            {
              q: this.state.q,
              limit: default_limit,
              offset: this.state.filter_offset,
              sort_column: this.state.column,
              sort_order: this.state.order,
              venue: venue_key,
              any_client_tags: this.state.any_client_tags,
              format: this.state.format,
              emma_group_id: this.state.emma_group_id,
              mailchimp_group_id: this.state.mailchimp_group_id,
              mailchimp_audience_id: this.state.mailchimp_audience_id,
              include_marketing_segmentation: true,
              hide_group_clients: true,
            },
            this.state.cursor ? { cursor: this.state.cursor } : {},
            filters
          )
        },

        fetchData: function () {
          if (this.state.is_fetch_in_progress) {
            return
          }

          if (this.state.is_first_load) {
            $.ajax({
              url: `/manager/${venue_key}/data/bookedbynames?include_integ_users=True`,
              success: response => {
                if (!response.payload.hasOwnProperty('content') || !response.payload.content.hasOwnProperty('booked_by_users')) {
                  Interface._alert('Could not load booked by names')
                }

                const bb_users = response.payload.content.booked_by_users

                this.setState({
                  booked_by_users: bb_users,
                })
              },
              error: () => {
                Interface._alert('Could not load booked by names')
              },
            })
          }

          $.ajax({
            url: '/api-yoa/clients',
            data: this.getParams(),
            beforeSend: _.bind(function () {
              this.setState({ is_fetch_in_progress: true })
            }, this),
            success: _.bind(function (response) {
              const newParams = _.object(
                _.filter(_.pairs(this.state), function (x) {
                  return _.contains(track_params, x[0]) && (!_.isEmpty(x[1]) || _.isNumber(x[1]) || _.isBoolean(x[1]))
                })
              )
              const newUrl = window.location.href.split('?')[0] + '?' + $.param(newParams)
              if (!this.state.popped_state && history && history.pushState) {
                history.pushState(newParams, '', newUrl)
              }

              this.setState({
                clients: response.data.clients,
                should_fetch_data: false,
                filter_offset: response.data.offset,
                num_found: response.data.num_found,
                is_nightlife: response.data.is_nightlife,
                selected_client_ids: [],
                select_all: false,
                is_fetch_in_progress: false,
                popped_state: false,
                post_q: this.state.q,
              })
            }, this),
            error: function () {
              Interface._alert('Oops... error encountered. Please retry!')
            },
            complete: _.bind(function () {
              this.setState({
                should_fetch_data: false,
                is_fetch_in_progress: false,
                is_first_load: false,
              })
            }, this),
          })
        },

        onNextHandler: function () {
          this.setState({
            filter_offset: this.state.filter_offset + default_limit,
            should_fetch_data: true,
          })
        },

        onPrevHandler: function () {
          this.setState({
            filter_offset: this.state.filter_offset - default_limit,
            should_fetch_data: true,
          })
        },

        changeFilterValue: function (filterName, filterValue) {
          const states = this.state
          states[filterName] = filterValue
          states['should_fetch_data'] = true
          this.setState(states)
        },

        refetchData: function () {
          const states = this.state
          states['should_fetch_data'] = true
          this.setState(states)
        },

        componentWillUnmount: function () {
          window.removeEventListener('popstate', this.onPopStateHandler, false)
        },

        onPopStateHandler: function (event) {
          const filterParams = _.filter(_.keys(this.state), function (x) {
              return x.includes('filter_')
            }),
            oldFilterParams = _.filter(_.keys(event.state), function (x) {
              return x.includes('filter_')
            }),
            missingParams = _.difference(filterParams, oldFilterParams),
            missingFilters = _.object(
              _.map(missingParams, function (p) {
                return [p, null]
              })
            )
          const oldState = _.extend({}, event.state, missingFilters, {
            popped_state: true,
            should_fetch_data: true,
          })
          this.setState(oldState)
        },

        componentDidUpdate: function () {
          if (this.state.should_fetch_data) {
            this.fetchData()
          } else {
            this.refs['footer'].setState({
              filter_offset: this.state.filter_offset,
              num_found: this.state.num_found,
            })
          }
        },

        onKeyUpHandler: function (e) {
          this.setState({ column: null, order: null, should_fetch_data: false })
          if (e.which === 13) {
            this.setState({
              q: $(e.currentTarget).val(),
              filter_offset: 0,
              should_fetch_data: true,
            })
          } else {
            this.setState({ q: $(e.currentTarget).val() })
          }
        },

        onClickSort: function (column, order) {
          this.setState({
            column: column,
            order: order,
            should_fetch_data: true,
          })
        },

        onFilterDelete: function (name, val) {
          const states = this.state
          if (_.isArray(states[name])) {
            states[name] = _.difference(states[name], [val])
          } else {
            states[name] = ''
          }
          states['filter_offset'] = 0
          states['should_fetch_data'] = true
          this.setState(states)
        },

        getFiltersSelection: function () {
          const tag_colors_objs = _.map(this.props.tag_groups, function (tag_group) {
            const groupings = tag_group.tags.map(function (tag) {
              const inner_color_mapping = {}
              inner_color_mapping[tag_group.id + '##' + tag_group.name + '##' + tag] = tag_group.color_hex
              return inner_color_mapping
            }, this)

            return _.extend.apply(null, [{}].concat(groupings))
          })

          const tag_colors = _.extend.apply(null, [{}].concat(tag_colors_objs))

          const applicable_tags = this.props.tag_groups,
            booked_by_users_lookup = this.state.booked_by_users.map(function (booked_name) {
              return [booked_name.name, booked_name.name]
            }),
            tags_lookup = _.flatten(
              applicable_tags.map(function (tag_group) {
                return tag_group.tags.map(function (tag) {
                  return [tag_group.tag_name_displays[tag] || tag, tag_group.id + '##' + tag_group.name + '##' + tag]
                }, this)
              }),
              true
            ),
            member_groups_lookup = this.state.member_groups.map(function (member_group) {
              return [member_group.name, member_group.id]
            }),
            venues_lookup = this.state.venues.map(function (venue) {
              return [venue.name, venue.id]
            }),
            vipFilter = this.state.filter_status ? (
              <helpers.filtersSelection
                name="filter_status"
                value={this.state.filter_status}
                label="Status"
                lookup={_.pairs(status_lookup)}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            visitsFilter = this.state.filter_visits ? (
              <helpers.filtersSelection
                value={this.state.filter_visits}
                name="filter_visits"
                label="Visits"
                lookup={_.pairs(visits_lookup)}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            ordersFilter = this.state.filter_orders ? (
              <helpers.filtersSelection
                value={this.state.filter_orders}
                name="filter_orders"
                label="Orders"
                lookup={_.pairs(orders_lookup)}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            birthMonthFilter = this.state.filter_birthday ? (
              <helpers.filtersSelection
                name="filter_birthday"
                label="Birthday"
                lookup={_.pairs(birthday_lookup)}
                value={this.state.filter_birthday}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            genderFilter = this.state.filter_gender ? (
              <helpers.filtersSelection
                name="filter_gender"
                label="Gender"
                lookup={_.pairs(gender_lookup)}
                value={this.state.filter_gender}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            lastVisitFilter = this.state.filter_last_visit ? (
              <helpers.filtersSelection
                name="filter_last_visit"
                label="Last visit"
                lookup={_.pairs(last_visit_lookup)}
                value={this.state.filter_last_visit}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            lastOrderFilter = this.state.filter_last_order ? (
              <helpers.filtersSelection
                name="filter_last_order"
                label="Last order"
                lookup={_.pairs(last_order_lookup)}
                value={this.state.filter_last_order}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            bookedByNameFilter = this.state.filter_bb_name ? (
              <helpers.filtersSelection
                name="filter_bb_name"
                label="Booked by name"
                lookup={booked_by_users_lookup}
                value={this.state.filter_bb_name}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            contactInfoFilter = this.state.filter_contact_info ? (
              <helpers.filtersSelection
                name="filter_contact_info"
                label="Contact Info"
                lookup={_.pairs(contact_lookup)}
                value={this.state.filter_contact_info}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            ratingFilter = this.state.filter_rating ? (
              <helpers.filtersSelection
                name="filter_rating"
                label="Rating"
                lookup={_.pairs(rating_lookup)}
                value={this.state.filter_rating}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            venuesFilter = (this.state.filter_venues || []).map(function (filter_venue) {
              return (
                <helpers.filtersSelection
                  name="filter_venues"
                  label="Venue"
                  lookup={venues_lookup}
                  value={filter_venue}
                  onDelete={this.onFilterDelete}
                />
              )
            }, this),
            marketingOptInFilter = this.state.filter_marketing_opt_in ? (
              <helpers.filtersSelection
                name="filter_marketing_opt_in"
                label="Marketing Opt In (Email)"
                lookup={venues_lookup}
                value={this.state.filter_marketing_opt_in}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            marketingOptInTextFilter = this.state.filter_marketing_opt_in_text ? (
              <helpers.filtersSelection
                name="filter_marketing_opt_in_text"
                label="Marketing Opt In (Text)"
                lookup={venues_lookup}
                value={this.state.filter_marketing_opt_in_text}
                onDelete={this.onFilterDelete}
              />
            ) : undefined,
            tagsFilter = (this.state.filter_tags || []).map(function (filter_tag) {
              return (
                <helpers.filtersSelection
                  name="filter_tags"
                  label="Tag"
                  color={tag_colors[filter_tag]}
                  lookup={tags_lookup}
                  value={filter_tag}
                  onDelete={this.onFilterDelete}
                />
              )
            }, this),
            excludeTagsFilter = (this.state.exclude_tags || []).map(function (filter_exclude_tag) {
              return (
                <helpers.filtersSelection
                  name="exclude_tags"
                  label="Exclude Tag"
                  color={'#d22d2d'}
                  lookup={tags_lookup}
                  value={filter_exclude_tag}
                  onDelete={this.onFilterDelete}
                />
              )
            }, this),
            memberGroupFilter = this.state.filter_member_group ? (
              <helpers.filtersSelection
                name="filter_member_group"
                label="Member Group"
                lookup={member_groups_lookup}
                value={this.state.filter_member_group}
                onDelete={this.onFilterDelete}
              />
            ) : undefined

          const noMatchesFound = <span>No matches found </span>
          const loadingMessage = <span>{helpers.WaitingQuip()}</span>
          const foundMatches = (
            <span>
              Showing {this.state.filter_offset}- {Math.min(this.state.filter_offset + default_limit, this.state.num_found)}
              &nbsp;of {this.state.num_found} results
              <span>{this.state.post_q ? ' matching "' + this.state.post_q + '"' : undefined}</span>
            </span>
          )
          const combinedMessages = this.state.num_found > this.state.filter_offset ? foundMatches : noMatchesFound
          const loadingResultsCombined = this.state.is_first_load ? loadingMessage : combinedMessages
          return (
            <div className="results-area">
              <div className="results-info-header">
                <div style={{ float: 'left', marginRight: 10 }}>{loadingResultsCombined}</div>
                {vipFilter}
                {visitsFilter}
                {ordersFilter}
                {birthMonthFilter}
                {genderFilter}
                {lastVisitFilter}
                {lastOrderFilter}
                {contactInfoFilter}
                {ratingFilter}
                {venuesFilter}
                {tagsFilter}
                {excludeTagsFilter}
                {memberGroupFilter}
                {bookedByNameFilter}
                {marketingOptInFilter}
                {marketingOptInTextFilter}
              </div>
            </div>
          )
        },

        onClickTagHandler: function (e) {
          const selectedTag = $(e.currentTarget),
            selectedTagId = selectedTag.data('tag_group_id'),
            selectedTagGroupName = selectedTag.data('name'),
            selectedTagText = selectedTag.data('tag'),
            compositeTag = selectedTagId + '##' + selectedTagGroupName + '##' + selectedTagText
          const any_client_tags = $('#any-client-tag').is(':checked')
          this.setState({
            filter_tags: _.union(this.state.filter_tags, [compositeTag]),
            filter_offset: 0,
            should_fetch_data: true,
            any_client_tags: any_client_tags,
          })
        },

        onClickExcludeTagHandler: function (e) {
          const selectedTag = $(e.currentTarget),
            selectedTagId = selectedTag.data('tag_group_id'),
            selectedTagGroupName = selectedTag.data('name'),
            selectedTagText = selectedTag.data('tag'),
            compositeTag = selectedTagId + '##' + selectedTagGroupName + '##' + selectedTagText
          this.setState({
            exclude_tags: _.union(this.state.exclude_tags, [compositeTag]),
            filter_offset: 0,
            should_fetch_data: true,
          })
        },

        toggleElement: function (arr, val) {
          return arr.includes(val) ? arr.filter(el => el !== val) : [...arr, val]
        },

        onClickVenueHandler: function (e) {
          const selectedVenue = $(e.currentTarget)
          const venueId = this.state.venues.find(i => i.name === selectedVenue.data('tag')).id
          this.setState({
            filter_venues: this.toggleElement(this.state.filter_venues, venueId),
            filter_offset: 0,
            should_fetch_data: true,
          })
        },

        toggleTagsDisplay: function () {
          this.setState({ show_tags: !this.state.show_tags })
        },

        onAllOrAnyTagClickHandler: function (any_client_tags) {
          this.setState({
            should_fetch_data: true,
            any_client_tags: any_client_tags,
          })
        },

        onSelectAllToggle: function (e) {
          if ($(e.currentTarget).is(':checked')) {
            this.setState({
              select_all: true,
              selected_client_ids: _.collect(this.state.clients, 'id'),
            })
          } else {
            this.setState({
              select_all: false,
              selected_client_ids: [],
            })
          }
        },

        mergeSelectedClients: function () {
          const selectedClientIds = this.state.selected_client_ids,
            form = $('<form></form>')
          const $form = $(form)
          $form.attr('action', manager_base_url + '/clients/merge')
          $form.attr('method', 'POST')

          for (var x = 0; x < selectedClientIds.length; x++) {
            $form.append("<input type='hidden' name='vgcid" + x + "' value='" + selectedClientIds[x] + "' />")
          }
          $form.append("<input type='hidden' name='numvgc' value='" + selectedClientIds.length + "' />")
          $form.append(Pmp.Client.Static.CsrfInput())
          $('body').append($form)
          $form.submit()
        },

        clientToggleHandler: function (e) {
          const selectedClient = $(e.currentTarget),
            selectedClientId = $(selectedClient).attr('id'),
            is_checked = $(selectedClient).is(':checked')
          var selected_client_ids = this.state.selected_client_ids
          if (is_checked) {
            selected_client_ids = _.union(selected_client_ids, [selectedClientId])
          } else {
            selected_client_ids = _.difference(selected_client_ids, [selectedClientId])
          }
          this.setState({
            select_all: selected_client_ids.length === this.state.clients.length,
            selected_client_ids: selected_client_ids,
          })
        },

        onExportHandler: function () {
          $.get('clients/export', this.getParams(), function (response) {})
        },

        onInviteClientsToAppHandler: function () {
          const selectedClientIds = this.state.selected_client_ids
          const params = this.getParams()
          params['selected_client_ids'] = selectedClientIds
          $.ajax({
            type: 'POST',
            url: `/api-yoa/client_membership/${venue_key}/bulk_invite`,
            data: JSON.stringify(params),
            contentType: 'application/json',
            success: function () {
              console.log('Successfully bulk invited.')
            },
          })
        },

        onCloseHandler: function () {
          this.setState({ format: undefined, inviteClientToApp: false })
        },

        onCloseErrorHandler: function () {
          this.setState({ error_message: undefined })
        },

        render: function () {
          let popup = undefined

          if (this.state.format) {
            if (this.state.clients.length)
              switch (this.state.format) {
                case 'mailchimp':
                  popup = (
                    <helpers.MailchimpExportPopup
                      has_marketing_filter={this.state.filter_marketing_opt_in}
                      onGroupSelect={k => this.setState({ mailchimp_group_id: k })}
                      onAudienceSelect={k => this.setState({ mailchimp_audience_id: k })}
                      onExportHandler={this.onExportHandler}
                      onCloseHandler={this.onCloseHandler}
                    />
                  )
                  break
                case 'excel':
                  popup = <helpers.ExcelExportPopup onExportHandler={this.onExportHandler} onCloseHandler={this.onCloseHandler} />
                  break
                case 'emma':
                  popup = (
                    <helpers.EmmaExportPopup
                      has_marketing_filter={this.state.filter_marketing_opt_in}
                      onGroupSelect={k => this.setState({ emma_group_id: k })}
                      onExportHandler={this.onExportHandler}
                      onCloseHandler={this.onCloseHandler}
                    />
                  )
                  break
              }
            else popup = <helpers.ErrorPopup onCloseHandler={this.onCloseHandler} message="No clients to export" />
          }

          if (this.state.inviteClientToApp) {
            popup = (
              <helpers.InviteClientPopup
                onInviteClientsToAppHandler={this.onInviteClientsToAppHandler}
                onCloseHandler={this.onCloseHandler}
                venueName={this.state.venues[0]['name']}
                numOfResults={this.state.num_found}
                selectedClientIds={this.state.selected_client_ids}
              />
            )
          }

          let errorPopup = undefined
          if (this.state.error_message) {
            errorPopup = <helpers.ErrorPopup onCloseHandler={this.onCloseErrorHandler} message={this.state.error_message} />
          }

          return (
            <span>
              {popup}
              {errorPopup}
              <div className="clients-table">
                <div id="interface">
                  <form className="styled">
                    <div className="search-interface">
                      <p>
                        <img className="glass" src={media_url + 'images/magnifier.gif'} alt="" />
                        <input
                          type="text"
                          onChange={this.onKeyUpHandler}
                          onKeyUp={this.onKeyUpHandler}
                          id="search-query-input"
                          name="q"
                          value={this.state.q}
                          disabled={this.state.is_fetch_in_progress}
                        />
                      </p>
                    </div>
                    <div id="client-lookup-search-btn"></div>
                    <helpers.SearchFilters
                      selectedFilters={this.state}
                      refetchData={this.refetchData}
                      changeFilterValue={this.changeFilterValue}
                    />
                    <helpers.VenuesFilter
                      selectedFilters={this.state}
                      onClickHandler={this.onClickVenueHandler}
                      changeFilterValue={this.changeFilterValue}
                    />
                    <helpers.TagsFilter
                      selectedFilters={this.state}
                      onClickHandler={this.onClickTagHandler}
                      onAllOrAnyTagClickHandler={this.onAllOrAnyTagClickHandler}
                      changeFilterValue={this.changeFilterValue}
                    />
                    <helpers.ExcludeTagsFilter
                      selectedFilters={this.state}
                      onClickHandler={this.onClickExcludeTagHandler}
                      changeFilterValue={this.changeFilterValue}
                    />
                    {this.getFiltersSelection()}
                  </form>
                </div>
                <div className="merge-btn-area" style={{ clear: 'both', paddingTop: 10 }}>
                  <div className="show-tags-results float-left">
                    <form className="styled">
                      <div className="inline">
                        <div className={'form-element checkbox ' + (this.state.select_all ? 'checked' : '')}>
                          <label htmlFor={'select-all-cbox'}>
                            <p className="input">
                              <input
                                onChange={this.onSelectAllToggle}
                                type="checkbox"
                                disabled={this.state.is_fetch_in_progress}
                                id={'select-all-cbox'}
                                checked={this.state.select_all}
                              />
                            </p>
                          </label>
                        </div>
                        <a
                          id="merge-selected-btn"
                          disabled={this.state.is_fetch_in_progress}
                          href="javascript:void(0);"
                          onClick={_.size(this.state.selected_client_ids) > 1 ? this.mergeSelectedClients : undefined}
                          className={_.size(this.state.selected_client_ids) > 1 ? undefined : 'disabled'}
                        >
                          Merge
                        </a>
                        <p className="tip-pop" id="merge-help">
                          Select at least 2 client profiles below that represent the same actual client and click this button to begin the
                          merge process.
                          <span className="up-arrow" />
                        </p>
                        <div className="form-element checkbox">
                          <label htmlFor={'show-tags-cbox'}>
                            <p className="input">
                              <input
                                disabled={this.state.is_fetch_in_progress}
                                onChange={this.toggleTagsDisplay}
                                type="checkbox"
                                id={'show-tags-cbox'}
                              />
                            </p>
                          </label>
                        </div>
                        <span className="label">Make tags visible</span>
                      </div>
                    </form>
                  </div>
                  <div className="float-end" />
                </div>
                <helpers.ClientHeader
                  column={this.state.column}
                  order={this.state.order}
                  onClickSort={this.onClickSort}
                  is_nightlife={this.state.is_nightlife}
                />
                <helpers.ClientRows
                  clients={this.state.clients}
                  show_tags={this.state.show_tags}
                  selected_client_ids={this.state.selected_client_ids}
                  clientToggleHandler={this.clientToggleHandler}
                  is_nightlife={this.state.is_nightlife}
                />
                <helpers.TableFooter ref="footer" onNextHandler={this.onNextHandler} onPrevHandler={this.onPrevHandler} />
              </div>
            </span>
          )
        },
      }),

      TableFooter: React.createClass({
        getInitialState: function () {
          return {
            filter_offset: 0,
            num_found: 0,
          }
        },

        render: function () {
          const nextLink =
            this.state.num_found >= this.state.filter_offset + default_limit ? (
              <div className="float-right">
                <a className="next-link" onClick={this.props.onNextHandler} href="javascript:void(0);">
                  <span>next {default_limit} &gt;&gt;</span>
                </a>
              </div>
            ) : undefined
          const prevLink = this.state.filter_offset ? (
            <div className="float-right">
              <a className="next-link" onClick={this.props.onPrevHandler} href="javascript:void(0);">
                <span>&lt;&lt; previous {default_limit}</span>
              </a>
            </div>
          ) : undefined

          return (
            <div className="prev-next-link-bar">
              {nextLink}
              {prevLink}
            </div>
          )
        },
      }),
    }

    $.when(
      $.ajax({
        url: client_tags_url,
      }),
      $.ajax({
        url: member_groups_url,
      })
    ).done(function (tags_result, members_result) {
      var tags_response = tags_result[0]
      var members_response = members_result[0]
      var any_client_tags_boolean = any_client_tags === 'true'
      React.render(
        <helpers.Table
          q={search_query}
          filter_offset={filter_offset}
          venue_key={venue_key}
          member_groups={members_response}
          bb_users={bb_users}
          venue_list_dict={venue_list_dict}
          tag_groups={tags_response.payload.content.tag_groups}
          filter_birthday={filter_birthday}
          filter_last_visit={filter_last_visit}
          filter_last_order={filter_last_order}
          filter_visits={filter_visits}
          filter_orders={filter_orders}
          filter_rating={filter_rating}
          filter_gender={filter_gender}
          filter_status={filter_status}
          filter_contact_info={filter_contact_info}
          filter_member_group={filter_member_group}
          filter_bb_name={filter_bb_name}
          filter_marketing_opt_in={filter_marketing_opt_in}
          filter_marketing_opt_in_text={filter_marketing_opt_in_text}
          filter_tags={filter_tags}
          exclude_tags={exclude_tags}
          any_client_tags={any_client_tags_boolean}
        />,
        elementToMount
      )
    })
  },
}

create_popup = (popup, title, message, actionButton, closeLabel, exportBtnLabel = 'export-btn') => {
  return (
    <div>
      <div id="cboxOverlay" style={{ opacity: 0.6, cursor: 'pointer', visibility: 'visible' }} />
      <div
        id="main-popup-parent"
        style={{
          zIndex: '10000',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'fixed',
          background: 'white',
        }}
      >
        <div id="main-popup">
          <div id="manager-search-confirm-export-popup" className="main-popup-contents">
            <div onClick={popup.props.onCloseHandler} style={{ fontSize: 21 }} className="float-right close-link">
              <a style={{ cursor: 'pointer' }} onClick={popup.props.onCloseHandler}>
                x
              </a>
            </div>
            <div className="overlay-header">
              <span className="mobile-identifier">{title}</span>
            </div>
            <div className="overlay-body">
              <div id="confirm-export-container">
                <div id="export-prompt-text">{message}</div>
                <div id="export-button-container">
                  {actionButton}
                  <p className="button" onClick={popup.props.onCloseHandler}>
                    <a id={exportBtnLabel} href="javascript:void(0);">
                      {closeLabel}
                    </a>
                  </p>
                  <div className="float-end" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
